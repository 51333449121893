exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-image-1-js": () => import("./../../../src/pages/image1.js" /* webpackChunkName: "component---src-pages-image-1-js" */),
  "component---src-pages-image-2-js": () => import("./../../../src/pages/image2.js" /* webpackChunkName: "component---src-pages-image-2-js" */),
  "component---src-pages-image-3-js": () => import("./../../../src/pages/image3.js" /* webpackChunkName: "component---src-pages-image-3-js" */),
  "component---src-pages-image-4-js": () => import("./../../../src/pages/image4.js" /* webpackChunkName: "component---src-pages-image-4-js" */),
  "component---src-pages-image-5-js": () => import("./../../../src/pages/image5.js" /* webpackChunkName: "component---src-pages-image-5-js" */),
  "component---src-pages-image-6-js": () => import("./../../../src/pages/image6.js" /* webpackChunkName: "component---src-pages-image-6-js" */),
  "component---src-pages-image-7-js": () => import("./../../../src/pages/image7.js" /* webpackChunkName: "component---src-pages-image-7-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

